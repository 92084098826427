import Vue from 'vue'
import App from './App.vue'
import store from './store'
import {router} from './router/index'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import interceptorSetup from './router/interceptors'
import 'es6-promise/auto';

import VueMeta from 'vue-meta'

import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})


// Require the polyfill before requiring any other modules.
require('intersection-observer');



axios.defaults.url = "https://db.hallo-beruf.de"
// axios.defaults.url = "https://db.staging.hallo-beruf.de"
// axios.defaults.url = "http://localhost:8000"
// axios.defaults.url = "http://192.168.10.136:8000"
// Vue.prototype.$localURL = "http://localhost:8080/"
Vue.prototype.$localURL = "https://hallo-beruf.de/"


axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.baseURL = axios.defaults.url + "/api/v1/"

//initialising axios interceptors for refresh token
interceptorSetup()

Vue.config.productionTip = false

Vue.use(require('vue-moment'));
Vue.use(require('moment/locale/de'))

import { SearchFilterHandler } from "./util/searchFilterHandler.js"

Vue.prototype.$SearchFilterHandler = SearchFilterHandler;
SearchFilterHandler.setup(); //create empty values

// register globally
const infiniteScroll = require('vue-infinite-scroll')

Vue.use(infiniteScroll)


new Vue({
  store,
  router,
  vuetify,
  data: () => ({
    alignments: [
      'start',
      'center',
      'end',
    ],
  }),
  render: h => h(App)
}).$mount('#app')


